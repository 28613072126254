<template>
     <b-media class="mb-4">
                  <template v-slot:aside>
                     <i class="dripicons-user font-size-28"></i>
                  </template>
                  <b-media-body>
                     <h5 class="mt-0 text-capitalize">{{authUser.user.username}}</h5>
                     <b-form-rating v-model="review.rating" variant="warning" inline no-border style="width: 10%" class="ml-n3"></b-form-rating>
                      <ValidationObserver ref="form" v-slot="{ invalid }">
                       <b-form @submit.prevent="postReview">
                        <ValidationProvider vid="review" name="review" rules="required" v-slot="{ errors }">
                     <b-form-group label-for="reviewTextarea">
                        <b-form-input id="reviewTextarea" type="text" placeholder="Your thoughts" size="lg" trim v-model="review.body"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                     </b-form-group>
                     </ValidationProvider>
                     <div class="d-flex justify-content-end">

                       <b-button type="submit" :variant="invalid ? 'light' : 'primary'" :disabled="invalid">Share review</b-button>
                       <b-button type="button" variant="none" class="iq-bg-danger ml-3" :class="invalid? 'd-none' : ''">Cancel</b-button>
                      </div>
                     </b-form>
                     </ValidationObserver>
                  </b-media-body>
                  </b-media>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  props: {
    bookSlug: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Setting/authUserState'
    })

  },
  data: () => ({
    review: {
      body: '',
      rating: 0,
      book: null
    }
  }),
  methods: {
    postReview () {
      const formData = new FormData()
      formData.append('user', this.authUser.user.pk)
      formData.append('book', this.bookSlug)
      formData.append('body', this.review.body)
      if (this.review.rating === 0) {
        this.review.rating = 1
      }
      formData.append('rating', this.review.rating)
      axios
        .post('reviews/', formData)
        .then(response => {
          //  emit this review and append it to what is already there
          this.$emit('review', response.data)
        //   this.reviews.results.push(response.data)
        })
        .catch(_error => {
          this.errorOnFetch = true
        })
    }
  }
}
</script>
